// export default function aaaaaa() {
//     let websocket = null;

//     function bbbbbb() {

//         // //判断浏览器是否支持websocket
//         if ('WebSocket' in window) {
//             // 建立socket连接
//             // var param=returnCitySN["cip"]+' --- '+returnCitySN["cname"]+' --- '+new Date().getTime();
//             // websocket = new WebSocket("ws://dev-api.gendome.net/aiot-datacenter/websocket/" + new Date().getTime());
//             // websocket = new WebSocket("ws://dev-api.gendome.net/aiot-devicecenter/websocket/deviceAlarm");

//             websocket = new WebSocket("wss://dev-api.gendome.net/aiot-devicecenter/websocket/deviceAlarm");

//             console.log("view page support websoket")
//         } else {
//             console.log("view page dosnt support websoket")
//         }
//     }






//     //websocket
//     //    let websocket = null;
//     // //判断浏览器是否支持websocket
//     // if ('WebSocket' in window) {
//     //   // 建立socket连接
//     //   // var param=returnCitySN["cip"]+' --- '+returnCitySN["cname"]+' --- '+new Date().getTime();
//     //   // websocket = new WebSocket("ws://dev-api.gendome.net/aiot-datacenter/websocket/" + new Date().getTime());
//     //   // websocket = new WebSocket("ws://dev-api.gendome.net/aiot-devicecenter/websocket/deviceAlarm");

//     //   websocket = new WebSocket("wss://dev-api.gendome.net/aiot-devicecenter/websocket/deviceAlarm");

//     //   console.log("view page support websoket")
//     // } else {
//     //   console.log("view page dosnt support websoket")
//     // }


//     // //连接成功回调函数
//     // websocket.onopen = function () {
//     //   console.log("已成功连接!")
//     // }
//     // //连接关闭回调函数
//     // websocket.onclose = function () {
//     //   websocket.close();
//     // }
//     // //接收消息回调函数
//     // websocket.onmessage = function (event) {
//     //   console.log('接收到消息',event.data)

//     // }

//     // //连接错误回调函数
//     // websocket.onerror = function () {
//     //   // setMessageInnerHTML("error")
//     // }

//     //   //监听窗口关闭事件
//     //   window.onbeforeunload = function () {
//     //   websocket.close();
//     // }


//     // // //将消息展示在网页上
//     // // function setMessageInnerHTML(message){
//     // //     document.getElementById('message').innerHTML += message + '</br/>';
//     // // }

//     // // //关闭连接函数
//     // // function close(){
//     // //     websocket.close();
//     // // }
//     // // //发送消息
//     // // function send(){
//     // // websocket.send(msg);
//     // // }

//     // setInterval(() => {

//     //   console.log('websocket.readyState',websocket.readyState)

//     //   if (websocket.readyState == 1) {
//     //     websocket.send("ping")
//     //   } else {
//     //     websocket = new WebSocket("wss://dev-api.gendome.net/aiot-devicecenter/websocket/deviceAlarm");
//     //   }
//     // }, 10000);

// }

// let websocket = null; 

// const alarmSocket = {

// open(){
//         //判断浏览器是否支持websocket
// if ('WebSocket' in window) {
//   // 建立socket连接
//   // var param=returnCitySN["cip"]+' --- '+returnCitySN["cname"]+' --- '+new Date().getTime();
//   // websocket = new WebSocket("ws://dev-api.gendome.net/aiot-datacenter/websocket/" + new Date().getTime());
//   // websocket = new WebSocket("ws://dev-api.gendome.net/aiot-devicecenter/websocket/deviceAlarm");

//   websocket = new WebSocket("wss://dev-api.gendome.net/aiot-devicecenter/websocket/deviceAlarm");

//   console.log("view page support websoket")
// } else {
//   console.log("view page dosnt support websoket")
// }
// }
// }



// export default alarmSocket



// //websocket
// let websocket = null;

// //判断浏览器是否支持websocket
// if ('WebSocket' in window) {
//   // 建立socket连接
//   websocket = new WebSocket("wss://dev-api.gendome.net/aiot-devicecenter/websocket/deviceAlarm");
//   console.log("view page support websoket")
// } else {
//   console.log("view page dosnt support websoket")
// }

// //接收消息回调函数
// websocket.onmessage = function (event) {
//   console.log('接收到消息', event.data)
// }

// let timer = setInterval(() => {
//   websocket.send("ping")
// }, 10000);

// clearInterval(timer);





// //websocket
// let websocket = null;
// //判断浏览器是否支持websocket
// if ('WebSocket' in window) {
//   // 建立socket连接
//   // var param=returnCitySN["cip"]+' --- '+returnCitySN["cname"]+' --- '+new Date().getTime();
//   // websocket = new WebSocket("ws://dev-api.gendome.net/aiot-datacenter/websocket/" + new Date().getTime());
//   // websocket = new WebSocket("ws://dev-api.gendome.net/aiot-devicecenter/websocket/deviceAlarm");

//   websocket = new WebSocket("wss://dev-api.gendome.net/aiot-devicecenter/websocket/deviceAlarm");

//   console.log("view page support websoket")
// } else {
//   console.log("view page dosnt support websoket")
// }

// //接收消息回调函数
// websocket.onmessage = function (event) {
//   console.log('接收到消息', event.data)

// }

// setInterval(() => {

//   console.log('websocket.readyState', websocket.readyState)

//   if (websocket.readyState == 1) {
//     websocket.send("ping")
//   } else {
//     websocket = new WebSocket("wss://dev-api.gendome.net/aiot-devicecenter/websocket/deviceAlarm");
//   }
// }, 10000);


// //连接成功回调函数
// websocket.onopen = function () {
//   console.log("已成功连接!")
// }
// //连接关闭回调函数
// websocket.onclose = function () {
//   websocket.close();
// }
// //接收消息回调函数
// websocket.onmessage = function (event) {
//   console.log('接收到消息',event.data)

// }

// //连接错误回调函数
// websocket.onerror = function () {
//   // setMessageInnerHTML("error")
// }

//   //监听窗口关闭事件
//   window.onbeforeunload = function () {
//   websocket.close();
// }


// // //将消息展示在网页上
// // function setMessageInnerHTML(message){
// //     document.getElementById('message').innerHTML += message + '</br/>';
// // }

// // //关闭连接函数
// // function close(){
// //     websocket.close();
// // }
// // //发送消息
// // function send(){
// // websocket.send(msg);
// // }






export default class AlarmSocket {

    constructor() {
        this.websocket = null
        this.timer = null
    }

    ///连接
    connect(callBack) {

        this.startConcent(callBack)

        this.timer = setInterval(() => {

            if (this.websocket.readyState == 1) {
                // console.log('发送消息')
                this.websocket.send("ping")

            } else {
                // console.log('进行重连')
                this.startConcent(callBack)

            }
        }, 300000);


    }

    close(){
        ///关闭链接
        this.websocket.close();
        clearInterval(this.timer);
    }


    startConcent(callBack) {


        //判断浏览器是否支持websocket
        if ('WebSocket' in window) {
            // 建立socket连接
            var websocketUrl =  process.env.VUE_APP_SOCKET_API + 'aiot-devicecenter/websocket/deviceAlarm'
            this.websocket = new WebSocket(websocketUrl);
        } else {
            // console.log("view page dosnt support websoket")
        }


        //连接成功回调函数
        this.websocket.onopen = function () {
        }
        //连接关闭回调函数
        this.websocket.onclose = function () {
            // this.websocket.close();
            // this.close();
        }

        //接收消息回调函数
        this.websocket.onmessage = function (event) {
            if (event.data != 'ping') {
                try {
                    let info = JSON.parse(event.data)
                    callBack(info.data)
                } catch (error) {
                    // console.log('JSON.parse：',error)
                }
            }
        }

        // this.websocket.onmessage = (event)=>{
        //     console.log(this.websocket)
        // }

        //连接错误回调函数
        this.websocket.onerror = function () {
            // setMessageInnerHTML("error")
            console.log('onerror')
        }



    }



    // ///开启定时器
    // startTimer() {
    //     this.timer = setInterval(() => {
    //         this.websocket.send("ping")
    //     }, 10000);
    // }

    ///关闭定时器
    closeTimer() {
        clearInterval(this.timer);
    }
}



