<template>
  <div class="box">
    <div style="width: 60%">
      <Chart :cdata="cdata" />
    </div>
    <div style="flex: 1">
      <dv-scroll-board :config="config" style="width: 100%; height: 460px" />
    </div>
  </div>
</template>

<script>
import Chart from "./chart.vue";
import netConstants from "../../../../net/netConstants";
import { formatTime } from "../../../../utils/index.js";
import Moment from "moment";

export default {
  data() {
    return {
      drawTiming: null,
      cdata: {
        maxData: 12000,
        year: null,
        weekCategory: [],
        weekMaxData: [],
        weekLineData: [],
        radarDataAvg: [],
        // radarData: []
      },
      config: {},
    };
  },
  components: {
    Chart,
  },
  mounted() {
    // this.drawTimingFn();
    // this.cdata.year = new Date().getFullYear();
    // this.getDateString();
    // this.getChargeLast7dayAlarm();
    // this.getFaultWarning();
    this.$EventBus.$on("globalRefresh", () => {
      this.getDateString();
      // this.getChargeLast7dayAlarm();
      this.getPeriodLast7dayAlarm();
      this.getFaultWarning();
    });

    this.$EventBus.$on("warningRefresh", () => {
      this.getPeriodLast7dayAlarm();
      this.getFaultWarning();
    });
  },
  beforeDestroy() {
    clearInterval(this.drawTiming);
  },
  methods: {
    getFaultWarning() {
      this.$get(netConstants.api_faultWarning, {
        num: 50,
      }).then((res) => {
        if (res.data.code == 200) {
          let newData = res.data.data;
          let data = newData.map((p) => {
            return [p.codeName, p.mac, formatTime(p.ts, "MM-dd HH:mm:ss")];
          });
          this.config = {
            header: this.$t("system.box7.headers"),
            data,
            headerBGC: "#68d9de",
            columnWidth: [120, 130, 120],
          };
        }
      });
    },
    drawTimingFn() {
      this.setData();
      this.drawTiming = setInterval(() => {
        this.setData();
      }, 6000);
    },
    setData() {
      // 清空轮询数据
      this.cdata.weekCategory = [];
      this.cdata.weekMaxData = [];
      this.cdata.weekLineData = [];
      // this.cdata.radarData = [];
      this.cdata.radarDataAvg = [];

      let dateBase = new Date();
      this.cdata.year = dateBase.getFullYear();
      // 周数据
      for (let i = 0; i < 7; i++) {
        // 日期
        let date = new Date();
        this.cdata.weekCategory.unshift(
          [date.getMonth() + 1, date.getDate() - i].join("/")
        );

        // 折线图数据
        this.cdata.weekMaxData.push(this.cdata.maxData);
        let distance = Math.round(Math.random() * 11000 + 500);
        this.cdata.weekLineData.push(distance);

        // 雷达图数据
        // // 我的指标
        let averageSpeed = +(Math.random() * 5 + 3).toFixed(3);
        // let maxSpeed = averageSpeed + +(Math.random() * 3).toFixed(2);
        // let hour = +(distance / 1000 / averageSpeed).toFixed(1);
        // let radarDayData = [distance, averageSpeed, maxSpeed, hour];
        // this.cdata.radarData.unshift(radarDayData);

        // 平均指标
        let distanceAvg = Math.round(Math.random() * 8000 + 4000);
        let averageSpeedAvg = +(Math.random() * 4 + 4).toFixed(3);
        let maxSpeedAvg = averageSpeedAvg + +(Math.random() * 2).toFixed(2);
        let hourAvg = +(distance / 1000 / averageSpeed).toFixed(1);
        let radarDayDataAvg = [
          distanceAvg,
          averageSpeedAvg,
          maxSpeedAvg,
          hourAvg,
        ];
        this.cdata.radarDataAvg.unshift(radarDayDataAvg); ///该值只用到index=6
      }
    },
    // getChargeLast7dayAlarm() {
    //   this.$get(netConstants.api_chargeLast7dayAlarm)
    //     .then((res) => {
    //       var info = res.data.data;
    //       if (res.data.code == 200) {
    //         //             // 清空轮询数据
    //         // this.cdata.maxData = this.cdata.maxData;
    //         // this.cdata.year = new Date().getFullYear();
    //         // this.cdata.weekCategory = [];//一周内每天日期
    //         // this.cdata.weekMaxData = [];//一周内每天最大值
    //         // this.cdata.weekLineData = [];//一周内每天数据
    //         // this.cdata.radarDataAvg = [];

    //         this.cdata.weekCategory = [];
    //         this.cdata.weekMaxData = [];
    //         this.cdata.weekLineData = [];
    //         // this.cdata.year = new Date().getFullYear();

    //         var tempMaxData = 0;
    //         info.alarmList.forEach((item) => {
    //           if (item.num > tempMaxData) {
    //             tempMaxData = item.num;
    //           }
    //         });
    //         ///确定图表纵坐标最大值,对5取整再+5
    //         this.cdata.maxData = (parseInt(tempMaxData / 5) + 1) * 5;

    //         info.alarmList.forEach((item) => {
    //           this.cdata.weekCategory.push(item.calcDay);
    //           this.cdata.weekMaxData.push(this.cdata.maxData);
    //           this.cdata.weekLineData.push(item.num);
    //         });

    //         for (let i = 0; i < 7; i++) {
    //           this.cdata.radarDataAvg.unshift([0, 0, 0, 0]); ///该值只用到index=6
    //         }
    //       }
    //     })
    //     .catch((error) => {
    //       console.log("getChargeLast7dayAlarm", error);
    //     });
    // },
    getPeriodLast7dayAlarm() {
      let timezoneOffset = new Date().getTimezoneOffset();
      if (timezoneOffset < 0) {
        timezoneOffset = 24 * 60 + timezoneOffset;
      }
      let params = {
        startTime: Moment().subtract(30, "days").startOf("day").valueOf(),
        endTime: Moment().endOf("day").valueOf(),
        period: 86400,
        timezoneOffset: timezoneOffset,
      };
      this.$get(netConstants.api_periodLast7dayAlarm, params)
        .then((res) => {
          var info = res.data.data;
          if (res.data.code == 200) {
            //             // 清空轮询数据
            // this.cdata.maxData = this.cdata.maxData;
            // this.cdata.year = new Date().getFullYear();
            // this.cdata.weekCategory = [];//一周内每天日期
            // this.cdata.weekMaxData = [];//一周内每天最大值
            // this.cdata.weekLineData = [];//一周内每天数据
            // this.cdata.radarDataAvg = [];

            this.cdata.weekCategory = [];
            this.cdata.weekMaxData = [];
            this.cdata.weekLineData = [];
            // this.cdata.year = new Date().getFullYear();

            var tempMaxData = 0;
            info.alarmList.forEach((item) => {
              if (item.num > tempMaxData) {
                tempMaxData = item.num;
              }
            });
            ///确定图表纵坐标最大值,对5取整再+5
            this.cdata.maxData = (parseInt(tempMaxData / 5) + 1) * 5;

            info.alarmList.forEach((item) => {
              let dateString = Moment(item.ts).format("MM/DD");
              this.cdata.weekCategory.push(dateString);
              this.cdata.weekMaxData.push(this.cdata.maxData);
              this.cdata.weekLineData.push(item.num);
            });

            for (let i = 0; i < 7; i++) {
              this.cdata.radarDataAvg.unshift([0, 0, 0, 0]); ///该值只用到index=6
            }
          }
        })
        .catch((error) => {
          console.log("getChargeLast7dayAlarm", error);
        });
    },
    getDateString() {
      // this.cdata.year = new Date().getFullYear();

      var year = new Date().getFullYear();
      var month = new Date().getMonth() + 1;
      var date = new Date().getDate();
      this.cdata.year = year + "/" + month + "/" + date;
    },
  },
};
</script>

<style lang="scss" scoped>
.box {
  width: 100%;
  height: 100%;
  display: flex;
  // align-items: center;
  // background-color: re;
}
</style>
