<template>
  <div id="index" ref="appRef">
    <div class="bg">
      <dv-loading v-if="loading">Loading...</dv-loading>
      <div v-else class="host-body">
        <div class="d-flex jc-center">
          <dv-decoration-10 class="dv-dec-10" />
          <div class="d-flex jc-center">
            <dv-decoration-8 class="dv-dec-8" :color="decorationColor" />
            <div class="title">
              <span class="title-text">{{ title }}数字能源平台</span>
              <dv-decoration-6
                class="dv-dec-6"
                :reverse="true"
                :color="['#50e3c2', '#67a1e5']"
              />
            </div>
            <dv-decoration-8
              class="dv-dec-8"
              :reverse="true"
              :color="decorationColor"
            />
          </div>
          <dv-decoration-10 class="dv-dec-10-s" />
        </div>

        <!-- 第二行 -->
        <div class="d-flex jc-between px-2">
          <div class="d-flex aside-width">
            <!-- <div class="react-left ml-4 react-l-s">
              <span class="react-left"></span>
              <span class="text">数据分析1</span>
            </div>
            <div class="react-left ml-3">
              <span class="text">数据分析2</span>
            </div> -->
          </div>
          <div class="d-flex aside-width">
            <div class="react-right mr-3" @click="$router.push(`/smartStation/${ requestKey }?name=${ title||'' }`)">
              <dv-border-box-9 :reverse="true">
                <span class="text fw-b">智慧电站</span>
              </dv-border-box-9>
            </div>
            <div class="react-right mr-4 react-l-s">
              <span class="react-after"></span>
              <span class="text"
                >{{ dateYear }} {{ dateWeek }} {{ dateDay }}</span
              >
            </div>
          </div>
        </div>

        <div class="body-box">
          <!-- 第三行数据 -->
          <div class="content-box">
            <div>
              <dv-border-box-12>
                <centerLeft1 />
              </dv-border-box-12>
            </div>
            <div>
              <dv-border-box-12>
                <centerLeft2 />
              </dv-border-box-12>
            </div>
            <!-- 中间 -->
            <div>
              <center />
            </div>
            <!-- 中间 -->
            <div>
              <centerRight2 />
            </div>
            <!-- <div>
              <dv-border-box-13>
                <centerRight1 />
              </dv-border-box-13>
            </div> -->
          </div>

          <!-- 第四行数据 -->
          <div class="bottom-box">
            <dv-border-box-13>
              <bottomLeft />
            </dv-border-box-13>
            <dv-border-box-12>
              <bottomRight />
            </dv-border-box-12>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import drawMixin from "../utils/drawMixin";
import { formatTime } from "../utils/index.js";
import centerLeft1 from "./centerLeft1";
import centerLeft2 from "./centerLeft2";
// import centerRight1 from "./centerRight1";
import centerRight2 from "./centerRight2";
import center from "./center";
import bottomLeft from "./bottomLeft";
import bottomRight from "./bottomRight";
import db from "../utils/localstorage.js";
import netConstants from "../net/netConstants";
import AlarmSocket from "../utils/alarmSocket";
import Moment from "moment";

export default {
  mixins: [drawMixin],
  data() {
    return {
      timing: null,
      loading: true,
      dateDay: null,
      dateYear: null,
      dateWeek: null,
      weekday: this.$t("system.weeks"),
      decorationColor: ["#568aea", "#000000"],
      alarmSocket: new AlarmSocket(),
      title: "",
    };
  },
  computed: {
    baseId() {
      return 'WmKn0UN4iR6HAHJgWMe1ALNOFJjpxa6i1tP1JsBZ1DvB534v89Xwhplk9nLi5Vn/NPYTQkIKb+RLUgqNjWIqqg=='
    },
    requestKey() {
      return encodeURIComponent(this.$route.query.id || this.baseId)
    }
  },
  components: {
    centerLeft1,
    centerLeft2,
    // centerRight1,
    centerRight2,
    center,
    bottomLeft,
    bottomRight,
  },
  mounted() {
    let that = this;
    this.title = this.$route.query.name;
    this.getToken(this.requestKey);
    this.timeFn();
    this.cancelLoading();
    this.alarmSocket.connect((info) => {
      /////显示通知并刷新告警相关接口
      that.showNotify(info);
      that.$EventBus.$emit("warningRefresh");
    });
    // // ///websocket测试
    // this.timer = setInterval(() => {
    //   this.getTestPush();
    // }, 6000);
  },
  beforeDestroy() {
    clearInterval(this.timing);
    this.alarmSocket.close();
  },
  methods: {
    showNotify(info) {
      let mac = info.mac;
      let date = Moment(info.ts).format("YYYY-MM-DD HH:mm:ss");
      let msg = info.codeName;

      const h = this.$createElement;
      this.$notify({
        title: this.$t("system.notify.title"),
        type: "warning",
        duration: 5000,
        message: h(
          // "div", { style: 'height: 50px; width: 100px'},
          "div",
          [
            h(
              "div",
              { style: "font-size:16px" },
              this.$t("system.notify.equipment") + "：" + mac
            ),
            h(
              "div",
              { style: "font-size:16px" },
              this.$t("system.notify.time") + "：" + date
            ),
            h(
              "div",
              { style: "font-size:16px" },
              this.$t("system.notify.alarmContent") + "：" + msg
            ),
          ]
        ),
      });
    },
    getToken(key) {
      let that = this;
      this.$post(netConstants.api_screen_getToken, { key: decodeURIComponent(key) })
        .then((res) => {
          let data = res.data.data;
          db.save("TOKEN_TYPE", data.token_type);
          db.save("ACCESS_TOKEN", data.access_token);
          ///获取到token之后再进行一次请求

          // this.$nextTick(function () {
          //   this.$EventBus.$emit("globalRefresh");
          // });

          // 问题：父组件mounted钩子比子组件mounted钩子先触发（未找到原因）
          setTimeout(() => {
            that.$nextTick(function () {
              that.$EventBus.$emit("globalRefresh");
            });
          }, 500);
        })
        .catch((error) => {
          console.log("getToken", error);
        });
    },
    timeFn() {
      this.timing = setInterval(() => {
        this.dateDay = formatTime(new Date(), "HH: mm: ss");
        this.dateYear = formatTime(new Date(), "yyyy-MM-dd");
        this.dateWeek = this.weekday[new Date().getDay()];
      }, 1000);
    },
    cancelLoading() {
      setTimeout(() => {
        this.loading = false;
      }, 500);
    },
    // // ///test
    // getTestPush() {
    //   this.$get(netConstants.api_testPush)
    //     .then((res) => {
    //       console.log("getTestPush", res)
    //     })
    //     .catch((error) => {
    //       console.log("getTestPush", error)
    //     });
    // },
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/scss/index.scss";
</style>
