<template>
  <div>
    <Chart :cdata="cdata" />
  </div>
</template>

<script>
import Chart from "./chart.vue";
import netConstants from "../../../../net/netConstants";
import Moment from "moment";

export default {
  data() {
    return {
      cdata: {
        category: [
          // "00:00",
          // "01:00",
          // "02:00",
          // "03:00",
          // "04:00",
          // "05:00",
          // "06:00",
          // "07:00",
          // "08:00",
          // "09:00",
          // "10:00",
          // "11:00",
          // "12:00",
          // "13:00",
          // "14:00",
          // "15:00",
          // "16:00",
          // "17:00",
          // "18:00",
          // "19:00",
          // "20:00",
          // "21:00",
          // "22:00",
          // "23:00",
          // "24:00",
        ],
        // 风能
        lineData: [
          // 10,
          // 11,
          // 13,
          // 11,
          // 12,
          // 12,
          // 9,
          // 13,
          // 15,
          // 12,
          // 9,
          // 17,
          // 16,
          // 8,
          // 12,
          // 13,
          // 17,
          // 12,
          // 8,
          // 14,
          // 16,
          // 17,
          // 11,
          // 10,
        ],
        // 太阳能
        barData: [
          // 20,
          // 21,
          // 23,
          // 21,
          // 22,
          // 22,
          // 19,
          // 23,
          // 25,
          // 22,
          // 19,
          // 27,
          // 26,
          // 18,
          // 22,
          // 23,
          // 27,
          // 22,
          // 18,
          // 24,
          // 26,
          // 27,
          // 21,
          // 20,
        ],
        // 其他
        rateData: [
          // 1,
          // 0,
          // 2,
          // 5,
          // 3,
          // 2,
          // 0,
          // 8,
          // 3,
          // 4,
          // 6,
          // 2,
          // 6,
          // 7,
          // 7,
          // 5,
          // 3,
          // 2,
          // 6,
          // 5,
          // 2,
          // 5,
          // 8,
          // 3,
        ],
      },
    };
  },
  components: {
    Chart,
  },
  mounted() {
    // this.setData();
    // this.getChargeLast7dayCharge()
    this.$EventBus.$on("globalRefresh", () => {
      this.getChargeLast7dayCharge();
    });
  },
  methods: {
    // 根据自己的业务情况修改
    setData() {
      for (let i = 0; i < this.cdata.barData.length - 1; i++) {
        let rate = this.cdata.barData[i] / this.cdata.lineData[i];
        this.cdata.rateData.push(rate.toFixed(2));
      }
    },
    getChargeLast7dayCharge() {
      let params = {
        startTime: Moment().subtract(6, "days").startOf("day").valueOf(),
        endTime: Moment().endOf("day").valueOf(),
        period: 86400,
        powerMode: 1,
      };

      this.$get(netConstants.api_chargeLast7dayCharge, params)
        .then((res) => {
          var info = res.data.data;
          if (res.data.code == 200) {
            this.cdata = {
              category: [],
              lineData: [],
              barData: [],
              rateData: [],
            };
            // inverterChargeCapacity//市电充电量集合
            // pvChargeCapacity//太阳能充电量集合
            // windChargeCapacity//风电充电量集合
            info.windChargeCapacity.forEach((item) => {
              this.cdata.category.push(Moment(item.ts).format("MM-DD"));
              this.cdata.lineData.push(item.sumVal);
            });
            info.pvChargeCapacity.forEach((item) => {
              this.cdata.barData.push(item.sumVal);
            });
            info.inverterChargeCapacity.forEach((item) => {
              this.cdata.rateData.push(item.sumVal);
            });
          }
        })
        .catch((error) => {
          console.log("getChargeLast7dayCharge", error);
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>
