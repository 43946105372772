<template>
  <div id="centerRight2">
    <div class="bg-color-black">
      <div class="d-flex pt-2 pl-2">
        <span>
          <icon name="align-left" class="text-icon"></icon>
        </span>
        <span class="fs-xl text mx-2">{{ $t("system.box4.title1") }}</span>
      </div>
      <div class="d-flex ai-center flex-column body-box">
        <!-- <dv-capsule-chart class="dv-cap-chart" :config="config" /> -->
        <!-- <centerRight2Chart1 /> -->

        <div class="dv-cap-chart-cz">
          <div
            class="item"
            style="width: 100%"
            v-for="item in titleItem"
            :key="item.title"
          >
            <div>
              <dv-digital-flop
                class="dv-dig-flop ml-1 mt-2 pl-3"
                :config="item.number"
              />
            </div>
            <p class="dv-item-title ml-3 fw-b fs-xl">{{ item.title }}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="bg-color-black2">
      <div class="d-flex pt-2 pl-2">
        <span>
          <icon name="align-left" class="text-icon"></icon>
        </span>
        <span class="fs-xl text mx-2">{{ $t("system.box4.title2") }}</span>
      </div>
      <div class="d-flex ai-center flex-column body-box">
        <dv-capsule-chart class="dv-cap-chart-2" :config="config2" />
      </div>
    </div>
  </div>
</template>

<script>
// import centerRight2Chart1 from '@/components/echart/centerRight/centerRightChart'
import netConstants from "../net/netConstants";
import Moment from "moment";

export default {
  // components: { centerRight2Chart1 }
  data() {
    return {
      config: {
        data: [
          {
            name: "Amazon",
            value: 167,
          },
          {
            name: "天猫",
            value: 67,
          },
          {
            name: "京东",
            value: 123,
          },
          {
            name: "官网",
            value: 55,
          },
          {
            name: "抖音",
            value: 98,
          },
        ],
      },
      config2: {
        data: [
          // {
          //   name: 'HOME 3000',
          //   value: 1000
          // },
          // {
          //   name: 'GO 1000',
          //   value: 67
          // },
          // {
          //   name: 'SOLOR PRO',
          //   value: 123
          // },
          // {
          //   name: 'WIND 200',
          //   value: 55
          // }
        ],
      },
      titleItem: [
        {
          title: this.$t("system.box4.dataTitle1"),
          number: {
            number: [0],
            toFixed: 0,
            textAlign: "left",
            content: "{nt}",
            style: {
              fontSize: 26,
            },
          },
        },
        {
          title: this.$t("system.box4.dataTitle2"),
          number: {
            number: [0],
            toFixed: 0,
            textAlign: "left",
            content: "{nt}",
            style: {
              fontSize: 26,
            },
          },
        },
        {
          title: this.$t("system.box4.dataTitle3"),
          number: {
            number: [0],
            toFixed: 0,
            textAlign: "left",
            content: "{nt}",
            style: {
              fontSize: 26,
            },
          },
        },
        {
          title: this.$t("system.box4.dataTitle4"),
          number: {
            number: [0],
            toFixed: 0,
            textAlign: "left",
            content: "{nt}",
            style: {
              fontSize: 26,
            },
          },
        },
      ],
    };
  },
  mounted() {
    // this.getAppUserStatistics()
    // this.getCategoryStatistics()
    this.$EventBus.$on("globalRefresh", () => {
      this.getAppUserStatistics();
      this.getCategoryStatistics();
    });
  },
  methods: {
    getAppUserStatistics() {
      let date = new Date();
      let params = {
        monthStartTime: Moment(date).startOf("month").valueOf(),
        monthEndTime: Moment(date).endOf("month").valueOf() - 999,
        todayStartTime: Moment(date).startOf("day").valueOf(),
        todayEndTime: Moment(date).endOf("day").valueOf() - 999,
      };

      this.$get(netConstants.api_appUserStatistics, params)
        .then((res) => {
          // todayAddNum 0//今日新增
          // todayOnlineNum 0//今日在线
          // monthlyActiveNum 5191//月活
          // totalNum 74//总数

          var info = res.data.data;
          if (res.data.code == 200) {
            this.titleItem[0].number.number = [info.todayAddNum || 0];
            this.titleItem[0].number = { ...this.titleItem[0].number };

            this.titleItem[1].number.number = [info.todayOnlineNum || 0];
            this.titleItem[1].number = { ...this.titleItem[1].number };

            this.titleItem[2].number.number = [info.monthlyActiveNum || 0];
            this.titleItem[2].number = { ...this.titleItem[2].number };

            this.titleItem[3].number.number = [info.totalNum || 0];
            this.titleItem[3].number = { ...this.titleItem[3].number };
          }
        })
        .catch((error) => {
          console.log("getAppUserStatistics", error);
        });
    },
    getCategoryStatistics() {
      this.$get(netConstants.api_categoryStatistics)
        .then((res) => {
          var listData = [];
          var list = res.data.data;
          if (res.data.code == 200) {
            list.forEach((item) => {
              listData.push({ name: item.productName, value: item.num });
            });

            // ///判断数组中是否已包含SOLOR PRO品类
            // if (listData.find(item => item.name === "SOLOR PRO")) {
            //   console.log("已存在SOLOR PRO")
            // } else {
            //   listData.push({ name: "SOLOR PRO", value: 0 })
            // }

            this.config2 = {
              data: listData,
              showValue: true,
            };
          }
        })
        .catch((error) => {
          console.log("getCategoryStatistics", error);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
#centerRight2 {
  $box-height: 410px;
  $box-width: 340px;
  padding: 5px;
  height: $box-height;
  width: $box-width;
  border-radius: 5px;

  .item {
    border-radius: 6px;
    padding-top: 8px;
    margin-top: 8px;
    width: 40%;
    height: 70px;

    .dv-dig-flop {
      width: 100%;
      height: 25px;
    }

    .dv-item-title {
      margin-top: 7px;
      color: white;
    }
  }

  .bg-color-black {
    padding: 5px;
    height: 210px;
    width: $box-width;
    border-radius: 10px;
  }

  .bg-color-black2 {
    padding: 5px;
    height: 210px;
    width: $box-width;
    border-radius: 10px;
  }

  .text {
    color: #c3cbde;
  }

  .body-box {
    border-radius: 10px;
    overflow: hidden;

    .dv-cap-chart {
      width: 100%;
      height: 160px;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
    }
    .dv-cap-chart-cz {
      width: 100%;
      height: 160px;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-column-gap: 20px;
    }
    .dv-cap-chart-2 {
      width: 90%;
      // height: 160px;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
    }
  }
}
</style>
