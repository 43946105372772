<template>
  <div>
    <Chart :cdata="cdata" />
  </div>
</template>

<script>
import Chart from "./chart.vue";
export default {
  props: {
    valueList: {
      type: Array,
    },
  },
  data() {
    return {
      cdata: {
        // xData: ["太阳能充电量", "风能充电量", "市电充电量"],
        xData: [],
        seriesData: [
          // { value: 0, name: "太阳能充电量" },
          // { value: 0, name: "风能充电量" },
          // { value: 0, name: "市电充电量" }
        ],
      },
    };
  },
  components: {
    Chart,
  },
  mounted() {},
  methods: {},
  watch: {
    valueList: {
      handler(newData) {
        this.cdata.xData = [
          this.$t("system.box1.echart.legend1"),
          this.$t("system.box1.echart.legend2"),
          this.$t("system.box1.echart.legend3"),
        ];
        this.cdata.seriesData = [];
        this.cdata.seriesData.push({
          value: newData[0],
          name: this.$t("system.box1.echart.legend1"),
        });
        this.cdata.seriesData.push({
          value: newData[1],
          name: this.$t("system.box1.echart.legend2"),
        });
        this.cdata.seriesData.push({
          value: newData[2],
          name: this.$t("system.box1.echart.legend3"),
        });

        // this.cdata.seriesData[0].value = newData[0]
        // this.cdata.seriesData[1].value = newData[1]
        // this.cdata.seriesData[2].value = newData[2]
        // this.cdata.seriesData = { ...this.cdata.seriesData }
        this.cdata = { ...this.cdata };
      },
    },
  },
};
</script>

<style lang="scss" scoped></style>
