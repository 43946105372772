<template>
  <div>
    <Chart :cdata="cdata" />
  </div>
</template>

<script>
import Chart from "./chart.vue";
import netConstants from "../../../../net/netConstants";
import nameMap from "@/common/map/nameMap.json";

export default {
  data() {
    return {
      cdata: [
        // {
        //   name: "加拿大",
        //   value: 4349,
        // },
        // {
        //   name: "中国",
        //   value: 13598,
        // },
        // {
        //   name: "芬兰",
        //   value: 8709,
        // },
        // {
        //   name: "法国",
        //   value: 5367,
        // },
        // {
        //   name: "印度",
        //   value: 4338,
        // },
        // {
        //   name: "意大利",
        //   value: 2406,
        // },
        // {
        //   name: "日本",
        //   value: 7420,
        // },
        // {
        //   name: "荷兰",
        //   value: 2712,
        // },
        // {
        //   name: "新西兰",
        //   value: 3609,
        // },
        // {
        //   name: "瑞典",
        //   value: 2054.232,
        // },
        // {
        //   name: "瑞士",
        //   value: 9382,
        // },
        // {
        //   name: "泰国",
        //   value: 1172,
        // },
        // {
        //   name: "土耳其",
        //   value: 10016,
        // },
        // {
        //   name: "乌克兰",
        //   value: 72137.546,
        // },
        // {
        //   name: "美国",
        //   value: 33719,
        // },
      ],
    };
  },
  components: {
    Chart,
  },
  mounted() {
    // this.getDeviceRegionStatistics()
    this.$EventBus.$on("globalRefresh", () => {
      this.getDeviceRegionStatistics();
    });
  },
  methods: {
    getDeviceRegionStatistics() {
      this.$get(netConstants.api_deviceRegionStatistics)
        .then((res) => {
          this.cdata = [];
          var list = res.data.data;
          if (res.data.code == 200) {
            list.forEach((item) => {
              this.cdata.push({ name: nameMap[item.region], value: item.num });
            });
          }
        })
        .catch((error) => {
          console.log("api_deviceRegionStatistics", error);
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>
