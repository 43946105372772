<template>
  <div id="center">
    <div class="up">
      <div
        class="bg-color-black item"
        v-for="item in titleItem"
        :key="item.title"
      >
        <p class="ml-3 colorBlue fw-b fs-xl">{{ item.title }}</p>
        <div>
          <dv-digital-flop
            class="dv-dig-flop ml-1 mt-2 pl-3"
            :config="item.number"
          />
        </div>
      </div>
    </div>
    <div class="down">
      <div class="ranking bg-color-black">
        <span>
          <icon name="chart-pie" class="text-icon"></icon>
        </span>
        <span class="fs-xl text mx-2 mb-1 pl-3">{{
          $t("system.box3.echartTitle1")
        }}</span>
        <dv-scroll-ranking-board
          v-if="ranking.data.length"
          class="dv-scr-rank-board mt-1"
          :config="ranking"
        />
        <dv-capsule-chart
          v-else
          class="dv-cap-chart-2"
          :config="rankingDefaultConfig"
        />
      </div>
      <div class="percent">
        <div class="item bg-color-black">
          <span>{{ $t("system.box3.echartTitle2") }}</span>
          <CenterChart
            :id="rate[0].id"
            :tips="rate[0].tips"
            :colorObj="rate[0].colorData"
          />
        </div>
        <div class="item bg-color-black">
          <span>{{ $t("system.box3.echartTitle3") }}</span>
          <CenterChart
            :id="rate[1].id"
            :tips="rate[1].tips"
            :colorObj="rate[1].colorData"
          />
        </div>
        <!-- <div class="water">
          <dv-water-level-pond class="dv-wa-le-po" :config="water" />
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import CenterChart from "@/components/echart/center/centerChartRate";
import netConstants from "../net/netConstants";
import Moment from "moment";
import nameMap from "@/common/map/nameMap.json";

export default {
  data() {
    return {
      titleItem: [
        {
          title: this.$t("system.box3.title1"),
          number: {
            number: [0],
            toFixed: 0,
            textAlign: "left",
            content: "{nt}",
            style: {
              fontSize: 26,
            },
          },
        },
        {
          title: this.$t("system.box3.title2"),
          number: {
            number: [0],
            toFixed: 0,
            textAlign: "left",
            content: "{nt}",
            style: {
              fontSize: 26,
            },
          },
        },
        {
          title: this.$t("system.box3.title3"),
          number: {
            number: [0],
            toFixed: 0,
            textAlign: "left",
            content: "{nt}",
            style: {
              fontSize: 26,
            },
          },
        },
        {
          title: this.$t("system.box3.title4"),
          number: {
            number: [0],
            toFixed: 0,
            textAlign: "left",
            content: "{nt}",
            style: {
              fontSize: 26,
            },
          },
        },
        {
          title: this.$t("system.box3.title5"),
          number: {
            number: [0],
            toFixed: 0,
            textAlign: "left",
            content: "{nt}",
            style: {
              fontSize: 26,
            },
          },
        },
        {
          title: this.$t("system.box3.title6"),
          number: {
            number: [0],
            toFixed: 0,
            textAlign: "left",
            content: "{nt}",
            style: {
              fontSize: 26,
            },
          },
        },
      ],
      ranking: {
        data: [
          // {
          //   name: '美国',
          //   value: 55
          // },
          // {
          //   name: '日本',
          //   value: 120
          // },
          // {
          //   name: '德国',
          //   value: 78
          // },
          // {
          //   name: '中国',
          //   value: 66
          // },
          // {
          //   name: '越南',
          //   value: 60
          // }
        ],
        carousel: "single",
        unit: " kWh",
      },
      rankingDefaultConfig: {
        data: [
          {
            name: "中国",
            value: 0,
          },
        ],
        unit: " kWh",
      },
      water: {
        data: [24, 45],
        shape: "roundRect",
        formatter: "{value}%",
        waveNum: 3,
      },
      // 通过率和达标率的组件复用数据
      rate: [
        {
          id: "centerRate1",
          tips: 0,
          colorData: {
            textStyle: "#3fc0fb",
            series: {
              color: ["#00bcd44a", "transparent"],
              dataColor: {
                normal: "#03a9f4",
                shadowColor: "#97e2f5",
              },
            },
          },
        },
        {
          id: "centerRate2",
          tips: 0,
          colorData: {
            textStyle: "#67e0e3",
            series: {
              color: ["#faf3a378", "transparent"],
              dataColor: {
                normal: "#ff9800",
                shadowColor: "#fcebad",
              },
            },
          },
        },
      ],
    };
  },
  components: {
    CenterChart,
  },
  mounted() {
    // this.getDeviceStatistics()
    // this.getCountryChargePowerList()
    this.$EventBus.$on("globalRefresh", () => {
      // console.log("已监听到定时任务")
      this.getDeviceStatistics();
      this.getCountryChargePowerList();
    });

    this.$EventBus.$on("warningRefresh", () => {
      this.getDeviceStatistics();
    });
  },
  methods: {
    getDeviceStatistics() {
      let date = new Date();
      let params = {
        monthStartTime: Moment(date).startOf("month").valueOf(),
        monthEndTime: Moment(date).endOf("month").valueOf() - 999,
        todayStartTime: Moment(date).startOf("day").valueOf(),
        todayEndTime: Moment(date).endOf("day").valueOf() - 999,
      };

      this.$get(netConstants.api_deviceStatistics, params)
        .then((res) => {
          // console.log("getDeviceStatistics", res)

          // totalNum	设备累计激活总数
          // monthlyActiveNum	本月设备激活数
          // todayActiveNum	今日设备激活数
          // totalAlarmNum	设备告警总数
          // monthlyAlarmNum	本月设备告警数
          // todayAlarmNum	今日设备告警数
          // onlineRate	今日设备在线率%
          // activeRate	今日设备激活率%

          var info = res.data.data;
          if (res.data.code == 200) {
            // this.$delete(this.titleItem[0].number, 'number')
            // this.$set(this.titleItem[0].number, 'number', [info.totalNum])
            // this.$delete(this.titleItem[1].number, 'number')
            // this.$set(this.titleItem[1].number, 'number', [info.monthlyActiveNum])
            // this.$delete(this.titleItem[2].number, 'number')
            // this.$set(this.titleItem[2].number, 'number', [info.todayActiveNum])

            this.titleItem[0].number.number = [info.totalNum];
            this.titleItem[0].number = { ...this.titleItem[0].number };

            this.titleItem[1].number.number = [info.monthlyActiveNum];
            this.titleItem[1].number = { ...this.titleItem[1].number };

            this.titleItem[2].number.number = [info.todayActiveNum];
            this.titleItem[2].number = { ...this.titleItem[2].number };

            this.titleItem[3].number.number = [info.totalAlarmNum];
            this.titleItem[3].number = { ...this.titleItem[3].number };

            this.titleItem[4].number.number = [info.monthlyAlarmNum];
            this.titleItem[4].number = { ...this.titleItem[4].number };

            this.titleItem[5].number.number = [info.todayAlarmNum];
            this.titleItem[5].number = { ...this.titleItem[5].number };

            // this.titleItem = { ...this.titleItem }

            this.rate[0].tips = info.onlineRate;
            this.rate[1].tips = info.activeRate;
          }
        })
        .catch((error) => {
          console.log("getDeviceStatistics", error);
        });
    },
    getCountryChargePowerList() {
      this.$get(netConstants.api_countryChargePowerList)
        .then((res) => {
          var list = res.data.data;
          if (res.data.code == 200) {
            var newList = [];
            list.forEach((item) => {
              var tempMonthChargeCapacity = item.monthChargeCapacity.toFixed(2);
              newList.push({
                name: nameMap[item.country],
                value: tempMonthChargeCapacity,
              });
            });
            // console.log("getCountryChargePowerList", newList)
            this.ranking.data = newList;
            this.ranking = { ...this.ranking };
          }
        })
        .catch((error) => {
          console.log("getCountryChargePowerList", error);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
#center {
  display: flex;
  flex-direction: column;

  .up {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;

    .item {
      border-radius: 6px;
      padding-top: 8px;
      margin-top: 8px;
      width: 32%;
      height: 70px;

      .dv-dig-flop {
        width: 150px;
        height: 30px;
      }
    }
  }

  .down {
    padding: 6px 4px;
    padding-bottom: 0;
    width: 100%;
    display: flex;
    height: 255px;
    justify-content: space-between;

    .bg-color-black {
      border-radius: 5px;
    }

    .ranking {
      padding: 10px;
      width: 59%;

      .dv-scr-rank-board {
        height: 225px;
      }
    }

    .percent {
      width: 40%;
      display: flex;
      flex-wrap: wrap;

      .item {
        width: 50%;
        height: 120px;

        span {
          margin-top: 88px;
          font-size: 14px;
          display: flex;
          justify-content: center;
        }
      }

      .water {
        width: 100%;

        .dv-wa-le-po {
          height: 120px;
        }
      }
    }
  }
}
</style>
