<template>
  <div id="centerLeft1">
    <div class="bg-color-black">
      <div class="d-flex pt-2 pl-2">
        <span>
          <icon name="chart-bar" class="text-icon"></icon>
        </span>
        <div class="d-flex">
          <span class="fs-xl text mx-2">{{ $t("system.box1.title") }}</span>
          <dv-decoration-3 class="dv-dec-3" />
        </div>
      </div>
      <div class="d-flex jc-center">
        <CenterLeft1Chart :valueList="valueList" />
      </div>
      <!-- 4个主要的数据 -->
      <div class="bottom-data">
        <div
          class="item-box mt-2"
          v-for="(item, index) in numberData"
          :key="index"
        >
          <div class="d-flex">
            <!-- <span class="coin">$</span> -->
            <dv-digital-flop class="dv-digital-flop" :config="item.number" />
          </div>
          <p class="text" style="text-align: left">
            {{ item.text }}
            <span class="colorYellow">(kWh)</span>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CenterLeft1Chart from "@/components/echart/centerLeft/centerLeft1Chart";
import netConstants from "../net/netConstants";

export default {
  data() {
    return {
      valueList: [],
      numberData: [
        {
          number: {
            number: [0],
            toFixed: 2,
            textAlign: "left",
            content: "{nt}",
            style: {
              fontSize: 24,
            },
          },
          text: this.$t("system.box1.numDataText2"),
        },
        {
          number: {
            number: [0],
            toFixed: 2,
            textAlign: "left",
            content: "{nt}",
            style: {
              fontSize: 24,
            },
          },
          text: this.$t("system.box1.numDataText1"),
        },
        {
          number: {
            number: [0],
            toFixed: 2,
            textAlign: "left",
            content: "{nt}",
            style: {
              fontSize: 24,
            },
          },
          text: this.$t("system.box1.numDataText3"),
        },
        {
          number: {
            number: [0],
            toFixed: 2,
            textAlign: "left",
            content: "{nt}",
            style: {
              fontSize: 24,
            },
          },
          text: this.$t("system.box1.numDataText4"),
        },
      ],
    };
  },
  components: {
    CenterLeft1Chart,
  },
  mounted() {
    // this.getChargeAnalysis();
    // this.changeTiming()
    this.$EventBus.$on("globalRefresh", () => {
      this.getChargeAnalysis();
    });
  },
  methods: {
    getChargeAnalysis() {
      this.$get(netConstants.api_chargeAnalysis)
        .then((res) => {
          // todayChargeCapacity	今日充电总量
          // allDischargeCapacity	总发电总量
          // windChargeCapacity	风电充电量
          // pvChargeCapacity	太阳能充电量
          // inverterChargeCapacity	市电充电量

          var info = res.data.data;
          if (res.data.code == 200) {
            // console.log("getChargeAnalysis", info)

            this.numberData[0].number.number = [info.inverterChargeCapacity];
            this.numberData[1].number.number = [info.allDischargeCapacity];
            this.numberData[2].number.number = [info.windChargeCapacity];
            this.numberData[3].number.number = [info.pvChargeCapacity];
            this.numberData = { ...this.numberData };

            this.valueList = [
              info.pvChargeCapacity,
              info.windChargeCapacity,
              info.inverterChargeCapacity,
            ];
          }
        })
        .catch((error) => {
          console.log("getChargeAnalysis", error);
        });
    },
    changeTiming() {
      setInterval(() => {
        this.changeNumber();
      }, 3000);
    },
    changeNumber() {
      this.numberData.forEach((item, index) => {
        item.number.number[0] += ++index;
        item.number = { ...item.number };
      });
    },
  },
};
</script>

<style lang="scss" scoped>
// $box-width: 300px;
$box-width: 100%;
$box-height: 410px;

#centerLeft1 {
  padding: 16px;
  height: $box-height;
  width: $box-width;
  border-radius: 10px;

  .bg-color-black {
    height: $box-height - 30px;
    border-radius: 10px;
  }

  .text {
    color: #c3cbde;
  }

  .dv-dec-3 {
    position: relative;
    width: 100px;
    height: 20px;
    top: -3px;
  }

  .bottom-data {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 10px;
    .item-box {
      // & > div {
      //   padding-right: 5px;
      // }

      // font-size: 14px;
      // float: right;
      // position: relative;
      // width: 50%;
      width: 100%;
      color: #d3d6dd;

      .dv-digital-flop {
        width: 130px;
        // width: 100%;
        height: 30px;
      }

      // 金币
      .coin {
        position: relative;
        top: 6px;
        font-size: 20px;
        color: #ffc107;
      }

      .colorYellow {
        color: yellowgreen;
      }

      p {
        text-align: center;
      }
    }
  }
}
</style>
