<template>
  <div>
    <Echart
      id="centreLeft2Chart"
      ref="centreLeft2ChartRef"
      :options="options"
      height="360px"
      width="500px"
    ></Echart>
  </div>
</template>

<script>
import Echart from "@/common/echart";
export default {
  data() {
    return {
      options: {},
      netData: [],
    };
  },
  components: {
    Echart,
  },
  props: {
    cdata: {
      type: Array,
      default: () => [],
    },
  },
  watch: {
    cdata: {
      handler(newData) {
        this.netData = newData;
        this.options = {
          showLegendSymbol: true,
          tooltip: {
            //提示框
            trigger: "item",
            textStyle: {
              fontSize: 14,
              lineHeight: 22,
            },
            position: (point) => {
              // 固定在顶部
              return [point[0] + 50, point[1] - 20];
            },
          },
          visualMap: {
            //将数值的大小映射到明暗度
            min: 0,
            max: 10,
            show: false,
            seriesIndex: 0,
            // 颜色
            inRange: {
              color: ["rgba(41,166,206, .5)", "rgba(69,117,245, .9)"],
            },
          },
          geo: {
            // 底部背景
            show: true,
            // aspectScale: 0.85, //长宽比
            zoom: 1.2,
            // top: "10%",
            // left: "16%",
            map: "world",
            roam: false,
            itemStyle: {
              normal: {
                areaColor: "rgba(0,0,0,0)",
                shadowColor: "rgba(7,114,204, .8)",
                shadowOffsetX: 5,
                shadowOffsetY: 5,
              },
              emphasis: {
                areaColor: "#00aeef",
              },
            },
          },
          series: [
            {
              name: this.$t("system.box2.echart.seriesTitle"),
              type: "map",
              // aspectScale: 1, //长宽比
              zoom: 1.2,
              mapType: "world", // 自定义扩展图表类型
              // top: "10%",
              // left: "5%",
              itemStyle: {
                normal: {
                  color: "red",
                  areaColor: "rgba(19,54,162, .5)",
                  borderColor: "rgba(0,242,252,.3)",
                  borderWidth: 1,
                  shadowBlur: 7,
                  shadowColor: "#00f2fc",
                },
                emphasis: {
                  areaColor: "#4f7fff",
                  borderColor: "rgba(0,242,252,.6)",
                  borderWidth: 2,
                  shadowBlur: 10,
                  shadowColor: "#00f2fc",
                },
              },
              label: {
                show: false,
                formatter: (params) => `${params.name}`,
                position: "insideRight",
                textStyle: {
                  fontSize: 14,
                  color: "#efefef",
                },
                emphasis: {
                  textStyle: {
                    color: "#fff",
                  },
                },
              },
              data: newData,
            },
          ],
        };
        // 重新选择区域
        this.handleMapRandomSelect();
      },
    },
  },
  methods: {
    // 开启定时器
    startInterval() {
      const _self = this;
      // 应通过接口获取配置时间，暂时写死5s
      const time = 2000;
      if (this.intervalId !== null) {
        clearInterval(this.intervalId);
      }
      this.intervalId = setInterval(() => {
        _self.reSelectMapRandomArea();
      }, time);
    },
    // 重新随机选中地图区域
    reSelectMapRandomArea() {
      const length = this.netData.length;
      if (length >= 2) {
        this.$nextTick(() => {
          try {
            const map = this.$refs.centreLeft2ChartRef.chart;
            let index = Math.floor(Math.random() * length);
            while (index === this.preSelectMapIndex || index >= length) {
              index = Math.floor(Math.random() * length);
            }
            map.dispatchAction({
              type: "mapUnSelect",
              seriesIndex: 0,
              dataIndex: this.preSelectMapIndex,
            });
            map.dispatchAction({
              type: "showTip",
              seriesIndex: 0,
              dataIndex: index,
            });
            map.dispatchAction({
              type: "mapSelect",
              seriesIndex: 0,
              dataIndex: index,
            });
            this.preSelectMapIndex = index;
          } catch (error) {
            console.log(error);
          }
        });
      } else {
        if (length > 0) {
          const map = this.$refs.centreLeft2ChartRef.chart;
          map.dispatchAction({
            type: "showTip",
            seriesIndex: 0,
            dataIndex: 0,
          });
        }
      }
    },
    handleMapRandomSelect() {
      this.$nextTick(() => {
        try {
          const map = this.$refs.centreLeft2ChartRef.chart;
          const _self = this;
          setTimeout(() => {
            _self.reSelectMapRandomArea();
          }, 0);
          // 移入区域，清除定时器、取消之前选中并选中当前
          map.on("mouseover", function (params) {
            clearInterval(_self.intervalId);
            map.dispatchAction({
              type: "mapUnSelect",
              seriesIndex: 0,
              dataIndex: _self.preSelectMapIndex,
            });
            map.dispatchAction({
              type: "mapSelect",
              seriesIndex: 0,
              dataIndex: params.dataIndex,
            });
            _self.preSelectMapIndex = params.dataIndex;
          });
          // 移出区域重新随机选中地图区域，并开启定时器
          map.on("globalout", function () {
            _self.reSelectMapRandomArea();
            _self.startInterval();
          });
          this.startInterval();
        } catch (error) {
          console.log(error);
        }
      });
    },
  },
  beforeDestroy() {
    clearInterval(this.intervalId);
  },
};
</script>
