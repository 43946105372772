<template>
  <div>
    <!-- 年度开工率 -->
    <Echart
      :options="options"
      id="bottomLeftChart"
      height="480px"
      width="100%"
    ></Echart>
  </div>
</template>

<script>
import Echart from "@/common/echart";
export default {
  data() {
    return {
      options: {},
    };
  },
  components: {
    Echart,
  },
  props: {
    cdata: {
      type: Object,
      default: () => ({}),
    },
  },
  watch: {
    cdata: {
      handler(newData) {
        this.options = {
          tooltip: {
            trigger: "axis",
            backgroundColor: "rgba(255,255,255,0.1)",
            axisPointer: {
              type: "shadow",
              label: {
                show: true,
                backgroundColor: "#7B7DDC",
              },
            },
          },
          legend: {
            data: [
              this.$t("system.box5.echart.legend1"),
              this.$t("system.box5.echart.legend2"),
              this.$t("system.box5.echart.legend3"),
            ],
            textStyle: {
              color: "#B4B4B4",
            },
            top: "0%",
          },
          grid: {
            x: "8%",
            width: "88%",
            y: "4%",
          },
          xAxis: {
            data: newData.category,
            axisLine: {
              lineStyle: {
                color: "#B4B4B4",
              },
            },
            axisTick: {
              show: false,
            },
          },
          yAxis: [
            {
              splitLine: { show: false },
              axisLine: {
                lineStyle: {
                  color: "#B4B4B4",
                },
              },

              axisLabel: {
                formatter: "{value} kWh",
              },
            },
            {
              splitLine: { show: false },
              axisLine: {
                lineStyle: {
                  color: "#B4B4B4",
                },
              },
              axisLabel: {
                formatter: "{value} kWh",
              },
            },
          ],
          series: [
            {
              name: this.$t("system.box5.echart.legend3"),
              type: "line",
              smooth: true,
              showAllSymbol: true,
              symbol: "emptyCircle",
              symbolSize: 8,
              // yAxisIndex: 1,
              itemStyle: {
                normal: {
                  color: "#67e0e3",
                },
              },
              data: newData.rateData,
            },
            {
              name: this.$t("system.box5.echart.legend1"),
              type: "line",
              smooth: true,
              showAllSymbol: true,
              symbol: "emptyCircle",
              symbolSize: 8,
              // yAxisIndex: 1,
              itemStyle: {
                normal: {
                  color: "#ffdb5c",
                },
              },
              data: newData.barData,
            },
            {
              name: this.$t("system.box5.echart.legend2"),
              type: "line",
              smooth: true,
              showAllSymbol: true,
              symbol: "emptyCircle",
              symbolSize: 8,
              // yAxisIndex: 1,
              itemStyle: {
                normal: {
                  color: "#9fe6b8",
                },
              },
              data: newData.lineData,
            },
            // {
            //   name: "太阳能发电量",
            //   type: "bar",
            //   barWidth: 10,
            //   itemStyle: {
            //     normal: {
            //       barBorderRadius: 5,
            //       color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
            //         { offset: 0, color: "#956FD4" },
            //         { offset: 1, color: "#3EACE5" },
            //       ]),
            //     },
            //   },
            //   data: newData.barData,
            // },
            // {
            //   name: "风能发电量",
            //   type: "bar",
            //   barGap: "-100%",
            //   barWidth: 10,
            //   itemStyle: {
            //     normal: {
            //       barBorderRadius: 5,
            //       color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
            //         { offset: 0, color: "rgba(156,107,211,0.8)" },
            //         { offset: 0.2, color: "rgba(156,107,211,0.5)" },
            //         { offset: 1, color: "rgba(156,107,211,0.2)" },
            //       ]),
            //     },
            //   },
            //   z: -12,
            //   data: newData.lineData,
            // },
          ],
        };
      },
      immediate: true,
      deep: true,
    },
  },
};
</script>
